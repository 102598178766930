import React from "react";
import Layout from "../layout/layout";
import Bottom from "../layout/bottom";
import { useMedia } from "react-use";

const DataDeletion = (props) => {

    const isMobile = useMedia('(max-width: 992px)');

    return (
        <Layout isBasic={true}>
            <div style={{maxWidth: '1200px', margin: 'auto', padding: '120px 20px 80px'}}>
                <h4>Data deletion instructions</h4>
                <p>We only store your name, email and profile photo from your Facebook profile. If you would like to have this data
                removed, you can do so instantly within the Hostelify app by following these instructions:</p>
                <ol>
                    <li>Log in to your Hostelify app or website</li>
                    <li>Open the my account section by clicking on your avatar</li>
                    <li>Go to Settings</li>
                    <li>Click on delete my account and click yes on the confirmation.</li>
                </ol>
                <p>This will remove your account and any associated data. <strong>Note: This cannot be undone.</strong> </p>
            </div>
            <Bottom isMobile={isMobile} />
        </Layout>
    );

};

export default DataDeletion;